import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

// function getRandomInt(_min, _max) {
//   const min = Math.ceil(_min)
//   const max = Math.floor(_max)
//   return Math.random() * (max - min) + min
// }

/* eslint-disable global-require */
const data = {
  cards: [
    {
      token: '91716sshavgsa321asc32as',
      holderName: 'Jean Moraes',
      maskedNumber: '**** **** **** 3552',
      validate: '2021-10-23T10:48:42.4212865',
      flag: 'Visa',
      name: 'Visa - 3552',
      preferred: true,
    },
    {
      token: '91716sshavgsa321asc32as',
      holderName: 'Leandro Caetano',
      maskedNumber: '**** **** **** 1234',
      validate: '2021-10-23T10:48:42.4212865',
      flag: 'Master',
      name: 'Master - 1234',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/financial/creditCardList').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'token',
    sortDesc = false,
    status = null,
  } = config.params
  /* eslint-enable */
  const queryLowered = q.toLowerCase()

  const searchParams = cards => cards.holderName.toLowerCase().includes(queryLowered) || cards.maskedNumber.toLowerCase().includes(queryLowered)

  const filteredData = data.cards.filter(
    card =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      searchParams(card) &&
      card.status === (status || card.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      cards: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})
