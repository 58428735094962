import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// Table
import './data/extensions/good-table'
import './data/extensions/auto-suggest'
import './data/card/card-statistics'
import './data/card/card-analytics'

// Apps
import './data/apps/transactions'
import './data/apps/rewards-voucher'
import './data/apps/rewards-money'
import './data/apps/indicate'
import './data/apps/calendar'
import './data/apps/user'
import './data/apps/email'
import './data/apps/invoice'
import './data/apps/todo'
import './data/apps/chat'
import './data/apps/eCommerce'
import './data/apps/profiles'

// financial
import './data/apps/creditCardList'
import './data/apps/paymentsCompany'

// dashboard
import './data/dashboard/analytics'
import './data/dashboard/ecommerce'
import './data/dashboard/idq'

// pages
import './data/pages/faq-data'
import './data/pages/knowledge-base'
import './data/pages/pricing-data'
import './data/pages/account-setting'
import './data/pages/profile-data'
import './data/pages/blog'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
