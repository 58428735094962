export default [
  {
    path: '/users/profiles/list',
    name: 'profiles-list',
    component: () => import('@/views/users-config/profile/profile-list/ProfileList.vue'),
    meta: {
      resource: 'configProfile',
      action: 'read',
      pageTitle: 'Perfis de usuários',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Lista de Perfis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/profiles/new',
    name: 'new-profile',
    component: () => import('@/views/users-config/profile/profile-new/ProfileNew.vue'),
    meta: {
      resource: 'configProfile',
      action: 'edit',
      pageTitle: 'Perfis de usuário',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Perfis de usuários',
          to: { name: 'profiles-list' },
        },
        {
          text: 'Novo Perfil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users-config/users/users-list/UserList.vue'),
    meta: {
      resource: 'configUser',
      action: 'read',
      pageTitle: 'Lista de Usuários',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Lista de Usuários',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/new',
    name: 'users-new',
    component: () => import('@/views/users-config/users/user-new/UserNew.vue'),
    meta: {
      resource: 'configUser',
      action: 'edit',
      pageTitle: 'Criar novo usuário',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Novo Usuário',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/users-config/users/user-edit/UserEdit.vue'),
    meta: {
      resource: 'configUser',
      action: 'edit',
      pageTitle: 'Editar usuário',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Editar Usuário',
          active: true,
        },
      ],
    },
  },
]
