export default [
  {
    path: '/company/config',
    name: 'company-config',
    component: () => import('@/views/company/config/CompanyConfig.vue'),
    meta: {
      resource: 'settingBusiness',
      action: 'read',
      pageTitle: 'Configurações da Empresa',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Empresa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/marketing',
    name: 'company-marketing',
    component: () => import('@/views/company/marketing/PreviewArts.vue'),
    meta: {
      resource: 'settingBusiness',
      action: 'read',
      pageTitle: 'Gerar Imagens',
      breadcrumb: [
        {
          text: 'Configurações',
        },
        {
          text: 'Empresa',
          active: true,
        },
      ],
    },
  },
]
