export default {
  namespaced: true,
  state: {
    indicationsFilter: {
      searchQuery: null,
      periodDateFilter: null,
      statusFilter: null,
      campaignsFilter: null,
      isSingleDate: false,
      singleDateFilter: null,
    },
  },
  getters: {},
  mutations: {
    SET_FILTERS_INDICATION(state, data) {
      state.indicationsFilter.searchQuery = data.searchQuery
      state.indicationsFilter.periodDateFilter = data.periodDateFilter
      state.indicationsFilter.statusFilter = data.statusFilter
      state.indicationsFilter.campaignsFilter = data.campaignsFilter
      state.indicationsFilter.isSingleDate = data.isSingleDate
      state.indicationsFilter.singleDateFilter = data.singleDateFilter
    },
  },
  actions: {},
}
