export default {
  namespaced: true,
  state: {
    activeCompany: true,
  },
  getters: {
    getStatusCompany: state => state.activeCompany,
  },
  mutations: {
    TOGGLE_STATUS_COMPANY(state, status) {
      state.activeCompany = status
    },
  },
  actions: {},
}
