import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

function getRandomInt(_min, _max) {
  const min = Math.ceil(_min)
  const max = Math.floor(_max)
  return Math.random() * (max - min) + min
}

/* eslint-disable global-require */
const data = {
  rewards: [
    {
      coupon: 'X321S15',
      payoutDate: getRandomInt(0, 10) > 5 ? '18/01/2021' : null,
      status: 'Pago',
      descriptionVoucher: 'Mensalidade grátis',
      indicator: {
        name: 'Jean Moraes',
        phone: '(21)986142333',
        cpf: '179.559.760-70',
      },
    },
    {
      coupon: 'Xy32565',
      payoutDate: getRandomInt(0, 10) > 5 ? '18/01/2021' : null,
      status: 'Em aberto',
      descriptionVoucher: 'Hidratação grátis',
      indicator: {
        name: 'João da Silva Santos',
        phone: '(21)986142333',
        cpf: '145.960.627-22',
      },
    },
    {
      coupon: 'Xy32568',
      payoutDate: getRandomInt(0, 10) > 5 ? '18/01/2021' : null,
      status: 'Em aberto',
      descriptionVoucher: '50% de desconto em qualquer produto',
      indicator: {
        name: 'Pedro Henrique Mussi',
        phone: '(21)986142333',
        cpf: '350.110.740-48',
      },
    },
    {
      coupon: 'Xy32567',
      payoutDate: getRandomInt(0, 10) > 5 ? '18/01/2021' : null,
      status: 'Pago',
      descriptionVoucher: '30% de desconto no produto X',
      indicator: {
        name: 'Juliana Santos Azevedo Lima',
        phone: '(21)986142333',
        cpf: '862.899.530-79',
      },
    },
    {
      coupon: 'Xy32561',
      payoutDate: getRandomInt(0, 10) > 5 ? '18/01/2021' : null,
      status: 'Em aberto',
      descriptionVoucher: 'Mensalidade grátis',
      indicator: {
        name: 'Elaine Zorzenon',
        phone: '(21)986142333',
        cpf: '952.647.580-18',
      },
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/rewards/voucher').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    status = null,
    // stage = null,
    // campaign = null,
    // filterDate = null,
    // initialDate = null,
    // endDate = null,
  } = config.params
  /* eslint-enable */
  const queryLowered = q.toLowerCase()

  const searchParams = reward => reward.indicator.name.toLowerCase().includes(queryLowered)
                      || reward.indicator.cpf.toLowerCase().includes(queryLowered)
                      || reward.indicator.phone.includes(queryLowered)
                      || reward.coupon.toLowerCase().includes(queryLowered)
                      || reward.descriptionVoucher.toLowerCase().includes(queryLowered)

  // const dateSearch = reward => {
  //   const datesTypes = ['registrationDate', 'lastUpdateDate', 'expireDate']
  //   if (datesTypes.includes(filterDate)) { return reward[filterDate] >= initialDate && reward[filterDate] <= endDate }
  //   if (!filterDate) return true
  //   return true
  // }

  const filteredData = data.rewards.filter(
    reward =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      searchParams(reward) &&
      reward.status === (status || reward.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      rewards: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// mock.onGet('/campaigns/list/name').reply(() => {
//   const campaignsNameArray = data.rewards.map(item => item.campaignName)
//   const campaignsUnique = campaignsNameArray.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
//   const campaigns = campaignsUnique.reduce((acc, curr) => [...acc, { label: curr, value: curr }], [])
//   return [
//     200,
//     // campaigns,
//     { campaigns },
//   ]
// })
