import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  transactions: [
    {
      idTransaction: 'ABC123',
      item: 'Crédito de leads de indicação',
      quantity: 254,
      price: 0.9,
      value: 228.60,
      purchaseDate: '2021-10-23T10:48:42.4212865',
      validateDate: '2021-10-23T10:48:42.4212865',
    },
    {
      idTransaction: 'QWE456',
      item: 'Crédito de leads de indicação',
      quantity: 198,
      price: 0.85,
      value: 178.60,
      purchaseDate: '2021-09-23T10:48:42.4212865',
      validateDate: '2021-09-23T10:48:42.4212865',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/company/payments').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'idTransaction',
    sortDesc = false,
    status = null,
  } = config.params
  /* eslint-enable */
  const queryLowered = q.toLowerCase()

  const searchParams = transaction => transaction.item.toLowerCase().includes(queryLowered)

  const filteredData = data.transactions.filter(
    reward =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      searchParams(reward) &&
      reward.status === (status || reward.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      transactions: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})
