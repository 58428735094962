import Vue from 'vue'

// axios
import axios from 'axios'

const sso = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

const api = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // timeout: 1000,
  headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
})

// api.interceptors.response.use(
//   response => {
//     console.log('response interceptor: ', response)
//     return response
//   },
//   (error, payload) => {
//     console.log('error interceptor: ', error)
//     console.log('payload interceptor: ', payload)
//     return Promise.reject(error)
//   },
// )

Vue.prototype.$http = sso

export default sso

export {
  sso,
  api,
}
