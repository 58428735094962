import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  congratulations: {
    // name: 'John',
    saleToday: '0',
  },
  subscribersGained: {
    series: [
      {
        name: 'Indicadores',
        data: [0],
      },
    ],
    analyticsData: {
      subscribers: 0,
    },
  },
  ordersRecevied: {
    series: [
      {
        name: 'Compartilhamentos',
        data: [0],
      },
    ],
    analyticsData: {
      orders: 0,
    },
  },
  statisticsItems: [
    {
      icon: 'EyeIcon',
      color: 'light-info',
      title: '0',
      subtitle: 'Visualizações',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'MonitorIcon',
      color: 'light-primary',
      title: '0',
      subtitle: 'Acessos únicos',
      customClass: 'mb-2 mb-xl-0',
    },
    {
      icon: 'UserIcon',
      color: 'light-danger',
      title: '0',
      subtitle: 'Leads',
      customClass: 'mb-2 mb-sm-0',
    },
    {
      icon: 'AwardIcon',
      color: 'light-success',
      title: '0',
      subtitle: 'Conversões',
      customClass: '',
    },
  ],
  statisticsOrder: {
    series: [
      {
        name: '2020',
        data: [45, 85, 65, 45, 65],
      },
    ],
  },
  statisticsProfit: {
    series: [
      {
        data: [80, 30, 50, 30, 60, 45],
      },
    ],
  },
  earningsChart: {
    series: [0, 0],
  },
  revenue: {
    years: ['2020', '2019', '2018'],
    price: '25,852',
    budget: '56,800',
    revenueReport: {
      series: [
        {
          name: 'Earning',
          data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
        },
        {
          name: 'Expense',
          data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
        },
      ],
    },
    budgetChart: {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],
    },
  },
  companyTable: [
    // {
    //   name: 'Indique Vivo Fibra',
    //   campaignType: 'leadGenerate',
    //   qtdLead: '20',
    //   qtdSales: '10',
    //   ticket: '891.2',
    //   conversion: '50',
    // },
    // {
    //   name: 'Indique 300mb',
    //   campaignType: 'codeGenerate',
    //   qtdLead: '45',
    //   qtdSales: '22',
    //   ticket: '300.2',
    //   conversion: '150',
    // },
  ],
  goalOverview: {
    completed: '0',
    inProgress: '0',
    series: [0],
  },
}
/* eslint-disable global-require */
mock.onGet('/idq/data').reply(() => [200, data])
