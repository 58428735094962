export default [
  {
    path: '/validate-voucher/',
    name: 'validate-voucher',
    component: () => import('@/views/validate-voucher/validate/Validate.vue'),
    meta: {
      resource: 'indications',
      action: 'edit',
      pageTitle: 'Validar cupom',
      breadcrumb: [
        {
          text: 'Validar cupom',
          active: true,
        },
      ],
    },
  },
  {
    path: '/validate-voucher/generate-lead/',
    name: 'generate-lead',
    component: () => import('@/views/validate-voucher/validate/GenerateLead.vue'),
    meta: {
      resource: 'indications',
      action: 'edit',
      pageTitle: 'Gerar lead',
      breadcrumb: [
        {
          text: 'Validar cupom',
          to: { name: 'validate-voucher' },
        },
        {
          text: 'Gerar lead',
          active: true,
        },
      ],
    },
  },
  {
    path: '/validate-voucher/confirm',
    name: 'confirm-data',
    component: () => import('@/views/validate-voucher/validate/ConfirmData.vue'),
    meta: {
      resource: 'indications',
      action: 'edit',
      pageTitle: 'Confirmação',
      breadcrumb: [
        {
          text: 'Validar cupom',
          to: { name: 'validate-voucher' },
        },
        {
          text: 'Confirmação dos dados',
          active: true,
        },
      ],
    },
  },
]
