export default {
  namespaced: true,
  state: {
    chartSeries: [0, 0, 0, 0],
  },
  getters: {
    getChartSeries: state => state.chartSeries,
  },
  mutations: {
    SET_SHARED_METHODS_VALUES(state, values) {
      state.chartSeries = [...values]
    },
  },
  actions: {},
}
