export default {
  // Endpoints
  loginEndpoint: '/useraccount/login',
  registerEndpoint: '/useraccount/create',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  passwordRecovery: '/account/passwordrecovery',
  changePasswordRecovery: '/account/changepasswordrecovery',

  // Dashboard
  statisticsItems: '/GetCountViews',
  dashboardConversion: '/dashboard/conversion',
  dashboardUsersData: '/dashboard/usersData',
  tableOfCampaingConversionDashboard: '/dashboard/conversion/campaigns',
  listCampaingInDashboard: '/campaign/list/dashboard',

  // Profile
  createProfile: '/profile/create',
  listProfile: 'profile/list',
  updateProfile: 'profile/Update',
  deleteProfile: 'profile/delete',
  getListFeatures: '/ListFeatures',

  // Users
  createUser: 'useraccount/create',
  listUsers: 'useraccount/list',
  deleteUser: 'useraccount/delete',
  getUserSystem: 'useraccount/GetUserSystem',
  updateUserSystem: 'useraccount/updateUserSystem',

  // UserAccount
  changePassword: '/account/changepassword',
  updateUserAccount: '/useraccount/update',
  validadeAccount: '/useraccount/validation',

  // Campaing
  listCampaing: '/campaign/list',
  updateStatusCampaing: '/campaign/updateStatus',
  updateSalesButton: 'campaign/updateSalesButton',
  getCampaingByToken: '/campaign/GetByToken',
  createCampaing: '/campaign/create',

  // Indication
  indication: 'campaign/indication/list',
  detailsCoupon: '/coupon',

  // Validate Coupon
  validateCoupon: '/coupon/validate',
  createCoupon: '/coupon/create',
  checkoutCoupon: '/coupon/checkout',
  cancelateCoupon: '/coupon/cancelate',

  // financial
  getAllRangesIndications: '/range/getAll',
  associatePlan: '/company/AssociatePlan',
  companyActivePlan: '/company/ActivePlan',
  alterRecurringPayment: 'payment/AlterRecurringPayment',
  historyCompanyPlans: '/company/HistoryPlans',
  setPaymentDefault: '/payment/SetPaymentDefault',

  updateCurrentPlan: '/plan/AlterCurrentPlan',

  //  payment
  CheckCompanyPaymentStatus: '/payment/CheckCompanyPaymentStatus',
  ListPaymentMethods: '/payment/ListPaymentMethods',
  DeletePaymentMethods: '/payment/DeletePaymentMethods',
  AddPaymentMethod: '/payment/AddPaymentMethod',

  // Reward
  createPayment: 'payment/Create',
  payments: '/reward/payments',
  pay: '/reward/pay',
  voucher: '/reward/voucher',

  // Company & App Configs
  getCompany: 'company/getByToken',
  getSettingsRewards: '/company/getPaymentSettingsByToken',
  updateSettingRewards: '/company/updatePayment',

  updateInfoCompany: '/company/update',
  updateLogoCompany: '/company/logo',
  updateColorsCompany: '/company/css',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
