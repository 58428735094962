export default {
  namespaced: true,
  state: {
    showModalErro500: false,
    showConfigCompanyModal: false,
    isAPICallInProgress: false,
  },
  getters: {
    getStatusModalErro500: state => state.showModalErro500,
    getConfigCompanyModal: state => state.showConfigCompanyModal,
    isAPICallInProgress: state => state.isAPICallInProgress,
  },
  mutations: {
    TOGGLE_MODAL_ERRO_500(state, status) {
      state.showModalErro500 = status
    },
    TOGGLE_MODAL_CONFIG_COMPANY(state, status) {
      state.showConfigCompanyModal = status
    },
    SET_API_CALL_IN_PROGRESS: (state, status) => {
      state.isAPICallInProgress = status
    },
  },
  actions: {},
}
