import mock from '@/@fake-db/mock'

/* eslint-disable global-require */
const data = {
  profileList:
    [
      {
        profileName: 'Admin',
        qtdUsers: 10,
        permission: [
          {
            funcionalidades: 'Dashboard Conversão',
            editar: true,
            ver: true,
          },
          {
            funcionalidades: 'Campanhas',
            editar: true,
            ver: true,
          },
          {
            funcionalidades: 'Indicações',
            editar: true,
            ver: true,
          },
          {
            funcionalidades: 'Recompensas em voucher',
            editar: true,
            ver: true,
          },
          {
            funcionalidades: 'Recompensas em dinheiro',
            editar: true,
            ver: true,
          },
          {
            funcionalidades: 'Perfis',
            editar: true,
            ver: true,
          },
          {
            funcionalidades: 'Usuários',
            editar: true,
            ver: true,
          },
        ],
      },
      {
        profileName: 'Analista',
        qtdUsers: 5,
        permission: [
          {
            funcionalidades: 'Dashboard Conversão',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Campanhas',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Indicações',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Recompensas em voucher',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Recompensas em dinheiro',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Perfis',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Usuários',
            editar: false,
            ver: false,
          },
        ],
      },
      {
        profileName: 'Comercial',
        qtdUsers: 15,
        permission: [
          {
            funcionalidades: 'Dashboard Conversão',
            editar: false,
            ver: true,
          },
          {
            funcionalidades: 'Campanhas',
            editar: false,
            ver: false,
          },
          {
            funcionalidades: 'Indicações',
            editar: false,
            ver: false,
          },
          {
            funcionalidades: 'Recompensas em voucher',
            editar: false,
            ver: false,
          },
          {
            funcionalidades: 'Recompensas em dinheiro',
            editar: false,
            ver: false,
          },
          {
            funcionalidades: 'Perfis',
            editar: false,
            ver: false,
          },
          {
            funcionalidades: 'Usuários',
            editar: false,
            ver: false,
          },
        ],
      },
    ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/profiles').reply(() => [
  200,
  {
    profileList: data.profileList,
  },
])
