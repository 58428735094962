export default [
  {
    path: '/financial/hire-package',
    name: 'hire-package',
    component: () => import('@/views/financial/hire-package/HirePackage.vue'),
    meta: {
      resource: 'settingFinancial',
      action: 'read',
      pageTitle: 'Contratar pacote',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Contratar pacote',
          active: true,
        },
      ],
    },
  },
  {
    path: '/financial/checkout',
    name: 'checkout-credit-card',
    component: () => import('@/views/financial/checkout-credit-card/CheckoutCreditCard.vue'),
    meta: {
      resource: 'settingFinancial',
      action: 'edit',
    },
  },
  {
    path: '/financial/credit-cards-list',
    name: 'credit-card-list',
    component: () => import('@/views/financial/creditcard-list/CreditCardList.vue'),
    meta: {
      resource: 'settingFinancial',
      action: 'read',
      pageTitle: 'Gerenciar cartões',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Lista de cartões',
          active: true,
        },
      ],
    },
  },
  {
    path: '/financial/credit-card-new',
    name: 'credit-card-new',
    component: () => import('@/views/financial/creditcard-new/creditCardNew.vue'),
    meta: {
      resource: 'settingFinancial',
      action: 'edit',
      pageTitle: 'Adicionar novo cartão',
      breadcrumb: [
        {
          text: 'Financeiro',
        },
        {
          text: 'Adicionar cartão',
          active: true,
        },
      ],
    },
  },
  {
    path: '/financial/company-plan',
    name: 'company-plan',
    component: () => import('@/views/financial/company-plan/companyPlan.vue'),
    meta: {
      resource: 'settingFinancial',
      action: 'read',
    },
  },
  {
    path: '/financial/update-plan',
    name: 'update-company-plan',
    component: () => import('@/views/financial/update-plan/UpdatePlan.vue'),
    meta: {
      resource: 'settingFinancial',
      action: 'edit',
    },
  },
]
