export default [

  {
    path: '/rewards/money',
    name: 'rewards-money',
    component: () => import('@/views/rewards/rewards-money/RewardsMoneyList.vue'),
    meta: {
      resource: 'rewardMoney',
      action: 'read',
      pageTitle: 'Recompensas em dinheiro',
      breadcrumb: [
        {
          text: 'Recompensas',
          to: { name: 'rewards-money' },
        },
        {
          text: 'Em dinheiro',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rewards/voucher',
    name: 'rewards-voucher',
    component: () => import('@/views/rewards/rewards-voucher/RewardsVoucherList.vue'),
    meta: {
      resource: 'rewardVoucher',
      action: 'read',
      pageTitle: 'Recompensas em cupom',
      breadcrumb: [
        {
          text: 'Recompensas',
          to: { name: 'rewards-money' },
        },
        {
          text: 'Em cupom',
          active: true,
        },
      ],
    },
  },
]
