import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

// function getRandomInt(_min, _max) {
//   const min = Math.ceil(_min)
//   const max = Math.floor(_max)
//   return Math.random() * (max - min) + min
// }

/* eslint-disable global-require */
const data = {
  transactions: [
    {
      idTransaction: '91716',
      dateBilling: '2021-10-23T10:48:42.4212865',
      plan: 'Professional',
      qtdIndications: 250,
      value: 150,
      dateStatus: '2021-10-23T10:48:42.4212865',
      status: 'Pago',
      idTransation: '162751639',
    },
    {
      idTransaction: '56695',
      dateBilling: '2021-10-14T10:51:17.1627811',
      plan: 'Pacote',
      qtdIndications: 50,
      value: 75.89,
      dateStatus: '2021-10-14T10:51:17.1627811',
      status: 'Aguardando pagamento',
      idTransation: '162751639',
    },
    {
      idTransaction: '30307',
      dateBilling: '2021-09-21T15:38:22.3297653',
      plan: 'Mei',
      qtdIndications: 50,
      value: 29.90,
      dateStatus: '2021-09-21T15:38:22.3297653',
      status: 'Cancelado',
      idTransation: '162751639',
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/company/transactions').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'idTransaction',
    sortDesc = false,
    status = null,
  } = config.params
  /* eslint-enable */
  const queryLowered = q.toLowerCase()

  const searchParams = transaction => transaction.plan.toLowerCase().includes(queryLowered) || transaction.status.toLowerCase().includes(queryLowered)

  //   const searchParams = reward => reward.indicator.name.toLowerCase().includes(queryLowered)
  //                       || reward.indicator.cpf.toLowerCase().includes(queryLowered)
  //                       || reward.indicator.phone.includes(queryLowered)
  //                       || reward.coupon.toLowerCase().includes(queryLowered)
  //                       || reward.descriptionVoucher.toLowerCase().includes(queryLowered)

  const filteredData = data.transactions.filter(
    reward =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      searchParams(reward) &&
      reward.status === (status || reward.status),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      transactions: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// mock.onGet('/campaigns/list/name').reply(() => {
//   const campaignsNameArray = data.rewards.map(item => item.campaignName)
//   const campaignsUnique = campaignsNameArray.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
//   const campaigns = campaignsUnique.reduce((acc, curr) => [...acc, { label: curr, value: curr }], [])
//   return [
//     200,
//     // campaigns,
//     { campaigns },
//   ]
// })
