export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard/idq',
    name: 'dashboard-idq',
    component: () => import('@/views/dashboard/idq/Dashboard.vue'),
    meta: {
      resource: 'conversionDashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboard/users-data',
    name: 'dashboard-users-data',
    component: () => import('@/views/dashboard/users-data/Dashboard.vue'),
    meta: {
      resource: 'usersDataDashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboard/financial',
    name: 'dashboard-financial',
    component: () => import('@/views/dashboard/financial/Dashboard.vue'),
    meta: {
      resource: 'financialDashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboard/conversion',
    name: 'dashboard-conversion',
    component: () => import('@/views/dashboard/conversion/Dashboard.vue'),
    meta: {
      resource: 'conversionDashboard',
      action: 'read',
    },
  },
]
