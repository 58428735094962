export default [
  {
    path: '/indicates',
    name: 'apps-indicates',
    component: () => import('@/views/indicate/indicate-list/IndicateList.vue'),
    meta: {
      resource: 'indications',
      action: 'read',
      pageTitle: 'Indicações',
      breadcrumb: [
        {
          text: 'Indicações',
          active: true,
        },
      ],
    },
  },
]
