import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  indicates: [
    {
      coupon: 'X321S00',
      stage: 'Em Andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Controle',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S01',
      stage: 'Cancelado',
      status: 'Indicação Cancelada',
      campaignName: 'Indique Vivo Pós Fixa',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S02',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Pós Família',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S03',
      stage: 'Concluído',
      status: 'Cupom validado',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S04',
      stage: 'Em Andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S05',
      stage: 'Cancelado',
      status: 'Cupom expirado',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S06',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S07',
      stage: 'Concluído',
      status: 'Cupom validado',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S08',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S09',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S10',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S11',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S12',
      stage: 'Em andamento',
      status: 'Indicação concluida',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S13',
      stage: 'Concluído',
      status: 'Cupom validado',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S14',
      stage: 'Concluído',
      status: 'Cupom validado',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
    {
      coupon: 'X321S15',
      stage: 'Concluído',
      status: 'Cupom validado',
      campaignName: 'Indique Vivo Fibra',
      indicatorName: 'Paulo Gustavo',
      indicated: {
        name: 'Alan Joe',
        email: 'alan.joe@gmail.com',
        phone: '21990324329',
      },
      dates: {
        registrationDate: '05/03/2021',
        expireDate: '05/05/2021',
        lastUpdateDate: '15/03/2021',
      },
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/indicates').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    status = null,
    stage = null,
    campaign = null,
    filterDate = null,
    initialDate = null,
    endDate = null,
  } = config.params
  /* eslint-enable */
  const queryLowered = q.toLowerCase()

  const searchParams = indicate => indicate.indicatorName.toLowerCase().includes(queryLowered) || indicate.indicated.name.toLowerCase().includes(queryLowered) || indicate.indicated.email.toLowerCase().includes(queryLowered) || indicate.coupon.toLowerCase().includes(queryLowered)

  const dateSearch = indicate => {
    const datesTypes = ['registrationDate', 'lastUpdateDate', 'expireDate']
    if (datesTypes.includes(filterDate)) { return indicate[filterDate] >= initialDate && indicate[filterDate] <= endDate }
    if (!filterDate) return true
    return true
  }

  const filteredData = data.indicates.filter(
    indicate =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      searchParams(indicate) &&
      indicate.status === (status || indicate.status) &&
      indicate.stage === (stage || indicate.stage) &&
      indicate.campaignName === (campaign || indicate.campaignName) &&
      dateSearch(indicate),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      indicates: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

mock.onGet('/campaigns/list/name').reply(() => {
  const campaignsNameArray = data.indicates.map(item => item.campaignName)
  const campaignsUnique = campaignsNameArray.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
  const campaigns = campaignsUnique.reduce((acc, curr) => [...acc, { label: curr, value: curr }], [])
  return [
    200,
    // campaigns,
    { campaigns },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/indicates').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)
  // Assign Status
  user.status = 'active'

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/user\/users\/\d+/).reply(config => {
  // Get event id from URL
  let userId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex(e => e.id === userId)
  const user = data.users[userIndex]

  if (user) return [200, user]
  return [404]
})
