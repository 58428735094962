export default [

  {
    path: '/campaign/list',
    name: 'campaigns-list',
    component: () => import('@/views/campaign/campaign-list/CampaignList.vue'),
    meta: {
      resource: 'campaign',
      action: 'read',
      pageTitle: 'Campanhas',
      breadcrumb: [
        {
          text: 'Campanhas',
          to: { name: 'campaigns-list' },
        },
        {
          text: 'Lista de campanhas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign/new',
    name: 'campaign-new',
    component: () => import('@/views/campaign/campaign-new/CampaignNew.vue'),
    meta: {
      resource: 'campaign',
      action: 'edit',
      pageTitle: 'Nova campanha',
      breadcrumb: [
        {
          text: 'Campanhas',
          to: { name: 'campaigns-list' },
        },
        {
          text: 'Nova campanha',
          active: true,
        },
      ],
    },
  },
  {
    path: '/campaign/new/success',
    name: 'campaign-success',
    component: () => import('@/views/campaign/campaign-new/Success.vue'),
    meta: {
      resource: 'campaign',
      action: 'edit',
    },
  },
  {
    path: '/campaign/edit/:token',
    name: 'campaigns-edit',
    component: () => import('@/views/campaign/campaign-list/CampaignList.vue'),
    meta: {
      resource: 'campaign',
      action: 'edit',
    },
  },
]
